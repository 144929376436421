import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyA8WSXpSWdF8GTBQ-o5JVuVxKo4n_IXdt4",
    authDomain: "thorstenfranzbrune-3d1b1.firebaseapp.com",
    projectId: "thorstenfranzbrune-3d1b1",
    storageBucket: "thorstenfranzbrune-3d1b1.firebasestorage.app",
    messagingSenderId: "778680680750",
    appId: "1:778680680750:web:0d6678cbb85b3843cb3abf",
    measurementId: "G-FDHRR2ZZQZ"
};

export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Version control (mobile vs. PC) + lazy-load the AuthModal
document.addEventListener("DOMContentLoaded", () => {
    if (window.innerWidth >= 1300) {
        document.querySelector("#LandingPage").insertAdjacentHTML("beforeend", `
            <div style="height: 7%; display: flex; justify-content: end; align-items: end">
                <p id="LandingPageLogin" style="padding: 5px 10px 5px 10px; border: 1px solid green; border-radius: 5px; box-sizing: border-box; cursor: pointer" onmouseover="this.style.color= '#36B300'; this.style.backgroundColor= '#30332D'; this.style.transition='0.65s'" onmouseout="this.style.backgroundColor= '#000000'; this.style.transition='0.65s'">intern</p>
            <div>
        `);
        function authModal() {
            import(/* webpackChunkName: "authModal" */ "./components/auth/authModal.js");
            document.querySelector("#LandingPageLogin").removeEventListener("click", authModal);
        };
        document.querySelector("#LandingPageLogin").addEventListener("click", authModal);
    };
});
